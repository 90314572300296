import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TextBlock } from '../components/block-components/exports';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <TextBlock textAlign="center" maxWidth="600px" padding="60px 0">
      <h1>PAGE NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
      <p>
        Please <Link to="/contact">contact us</Link> if you think something's
        broken.
      </p>
    </TextBlock>
  </Layout>
);
export default NotFoundPage;
